import React, { useEffect, useState } from 'react';
import './components.css';

interface GenericBtnProps {
    title: string;
    onClick: () => void;
}

export const GenericBtn: React.FC<GenericBtnProps> = ({ title, onClick }) => {
    return (  
        <button className='generic-button w-100 vs-bg' onClick={onClick}>{title}</button>
    );
};