import React, { useEffect, useState, useRef } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { ResourceExplorerFile } from './ResourceExplorerFile';
import { FileUploader } from 'react-drag-drop-files';
import { GenericFile } from './GenericFile';
import { GenericInput } from './GenericInput';
import { GenericBtn } from './GenericBtn';

interface ResourceExplorerFilePreviewProps {
    pack: string;
    path: string;
    onSelect: () => void;
    onDeleted: () => void;
    onHidden: () => void;
}

export const ResourceExplorerFilePreview: React.FC<ResourceExplorerFilePreviewProps> = ({ pack, path, onSelect, onDeleted, onHidden }) => {    
    const isImage = path.endsWith(".dds");
    const fileName = path.split("/").pop();
    const isDirectory = !path.includes(".") || path == "..";

    return (
        <Modal size='lg' show={true} onHide={() => {
            onHidden();
        }}>
            <Card className='vs-bg p-2 resource-explorer-file-preview'>
                <Row>
                    <Col className='center-text'>
                        {isImage &&
                            <img src={modService.getImageUrl(pack, path)} alt={path} width={100} height={100} className='resource-explorer-image' />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className='center-text'>
                        <p className='filename-text monospace'>{fileName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='center-text'>
                        <GenericBtn title='Select' onClick={onSelect} />
                    </Col>
                </Row>
                {pack != 'lego-universe' && pack != 'wonderland' && <>
                <Row>
                    <Col className='center-text'>
                        <GenericBtn title='Delete' onClick={onDeleted} />
                    </Col>
                </Row>
                </>}
            </Card>
        </Modal>
    );
};