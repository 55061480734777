import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { ResourceExplorerFilePreview } from './ResourceExplorerFilePreivew';
import { JsxElement } from 'typescript';

interface ResourceExplorerFileProps {
    pack: string;
    path: string;
    onSelect: () => void;
    onDeleted: () => void;
}

export const ResourceExplorerFile: React.FC<ResourceExplorerFileProps> = ({ pack, path, onSelect, onDeleted }) => {
    const isImage = path.endsWith(".dds");
    const fileName = path.split("/").pop();

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const showPreviewModal = () => {
        console.log("Show preview");
        setShowPreview(true);
    }

    return (
        <Col md={2} className='center-text resource-explorer-container'>
            <Card className={`vs-bg p-2 resource-explorer-file`} onClick={showPreviewModal}>
                <Row>
                    <Col>
                        {isImage &&
                            <img src={modService.getImageUrl(pack, path)} alt={path} width={50} height={50} className='resource-explorer-image' />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className='filename-text monospace'>{fileName}</p>
                    </Col>
                </Row>
            </Card>
            {showPreview &&
                <ResourceExplorerFilePreview
                    pack={pack}
                    path={path}
                    onSelect={() => {
                        setShowPreview(false);
                        onSelect();
                    }}
                    onHidden={() => {
                        setShowPreview(false);
                    }}
                    onDeleted={() => {
                        setShowPreview(false);
                        onDeleted();
                    }}
                />
            }
        </Col>
    );
};