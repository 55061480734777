import React, { useEffect } from 'react';
import logo from './logo.svg';
import {
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button,
} from 'react-bootstrap';
import './components.css';
import { modService } from './ModService';
import { useState } from 'react';
import { CreateModPackCard } from './CreateModPackCard';
import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { CreateModCard } from './CreateModCard';
import { GenericText } from './GenericText';
import { CreateProjectCard } from './CreateProjectCard';
import { HotKeyHint } from './HotKeyHint';
import { LogMessage, Severity } from './Logger';

interface NavigationProps {
    guiEnabled: boolean;
    setGuiEnabled: (guiEnabled: boolean) => void;
    showUnity: boolean;
    setShowUnity: (showUnity: boolean) => void;
    showEditor: boolean;
    setShowEditor: (showEditor: boolean) => void;
    showExplorer: boolean;
    setShowExplorer: (showExplorer: boolean) => void;
    showLogger: boolean;
    setShowLogger: (showLogger: boolean) => void;
}

export const Navigation: React.FC<NavigationProps> = ({
    guiEnabled, setGuiEnabled,
    showEditor, setShowEditor,
    showExplorer, setShowExplorer,
    showLogger, setShowLogger,
    showUnity, setShowUnity
}) => {
    const [showCreateModPack, setShowCreateModPack] = useState<boolean>(false);
    const [showCreateMod, setShowCreateMod] = useState<boolean>(false);
    const [showCreateProject, setShowCreateProject] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<string>(Cookies.get('project') || 'default');
    const [allProjects, setAllProjects] = useState<string[]>([]);
    const [databaseIsRunning, setDatabaseIsRunning] = useState<boolean>(false);
    const [gameServerIsRunning, setGameServerIsRunning] = useState<boolean>(false);

    const onDeploy = () => {
        LogMessage("Deployment started, this might take awhile", Severity.INFO);
        modService.save().then(data => {
            if (data["error"]) {
                LogMessage("Error deploying", Severity.ERROR);
                LogMessage(data["error"], Severity.ERROR);
            }
            else {
                LogMessage("Deployed successfully", Severity.INFO);
            }
        });
    }

    const onLoad = () => {
        window.location.href = '/load';
    }

    const onCreateModPack = () => {
        setShowCreateModPack(true);
    }

    const onFinishCreateModPack = (value: {name: string, description: string, author: string, version: string}) => {
        setShowCreateModPack(false);
        
        modService.createPack(value.name, value.description, value.author, value.version).then(data => {
            if (!data) {
                LogMessage("Error creating mod pack", Severity.ERROR);
                return;
            }

            LogMessage("Created mod pack", Severity.INFO);
        });
    }

    const onFinishCreateProject = (value: string) => {
        setSelectedProject(value);
        setShowCreateProject(false);
    }

    const onSaveModPacks = () => {
        modService.saveModPacks().then(data => {
            if (!data) {
                LogMessage("Error saving mod packs", Severity.ERROR);
            }
            else {
                LogMessage("Saved mod packs", Severity.INFO);
            }
        });
    }

    const onCreateMod = () => {
        setShowCreateMod(true);
    }

    const onCreateProject = () => {
        setShowCreateProject(true);
    }

    const onFinishCreateMod = (value: any) => {
    
    }

    const updateProjectStatus = () => {
        modService.getProject(selectedProject).then((data) => {
            if (data == null) {
                return;
            }

            setDatabaseIsRunning(data.database);
            setGameServerIsRunning(data.gameServer);
        });
    }

    const fetchProjects = () => {
        modService.getProjects().then(data => {
            setAllProjects(data);
        });
    }

    // Fetch projects
    useEffect(() => {
        fetchProjects();
    }, []);

    // Fetch project status
    useEffect(() => {
        updateProjectStatus();
    }, [selectedProject]);

    const startProject = () => {
        LogMessage("Starting project, please wait", Severity.INFO);

        modService.startProject(selectedProject).then(() => {
            updateProjectStatus();

            LogMessage("Started project", Severity.INFO);
        });
    }

    const stopProject = () => {
        LogMessage("Stopping project, please wait", Severity.INFO);

        modService.stopProject(selectedProject).then(() => {
            updateProjectStatus();

            LogMessage("Stopped project", Severity.INFO);
        });
    }

    const onProjectSelected = (project: string) => {
        setSelectedProject(project);
        Cookies.set('project', project, { expires: 180, path: '' });
        fetchProjects();

        LogMessage("Selected project: " + project, Severity.INFO);
    }

    const onShowSource = () => {
        setGuiEnabled(false);
    }

    const onShowGui = () => {
        setGuiEnabled(true);
    }

    const onShowExplorer = () => {
        setShowExplorer(!showExplorer);
    }

    const onShowLogger = () => {
        setShowLogger(!showLogger);
    }

    const onShowUnity = () => {
        setShowUnity(!showUnity);
    }

    const onShowEditor = () => {
        setShowEditor(!showEditor);
    }

    const hotKeys = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            onSaveModPacks();
        }

        if (event.altKey && event.key === 'n') {
            event.preventDefault();
            onCreateMod();
        }

        if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
            onCreateModPack();
        }
        
        if (event.ctrlKey && event.key === 'd') {
            event.preventDefault();
            onDeploy();
        }

        if (event.altKey && event.key === 'u') {
            event.preventDefault();
            if (guiEnabled) {
                onShowSource();
            }
            else {
                onShowGui();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', hotKeys);

        return () => {
            document.removeEventListener('keydown', hotKeys);
        }
    }, []);

    return (
        <Navbar id='my-navbar' expand="lg" className='vs-bg'>
            {/* If we are on the '/' page, redirect to #, otherwise redirect to '/' */}
            {window.location.pathname === '/' ? (
                <Navbar.Brand href="#" className='logo-text wheat-text'>nejlika</Navbar.Brand>
            ) : (
                <Navbar.Brand href="/" className='logo-text wheat-text'>nejlika</Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
             {/* The rest should be on the right of the screen*/}
            <Navbar.Collapse id="basic-navbar-nav row">
                <div className=""></div>
                <Nav className="mr-auto right">
                    <NavDropdown title="Project" id="basic-nav-dropdown" className='vs-bg white-text'>
                        {allProjects.map((project) => (
                            <NavDropdown.Item className='navigation-item' key={project} onClick={() => onProjectSelected(project)}>
                                {project === selectedProject ? (
                                    <strong>{project}</strong>
                                ) : (
                                    project
                                )}
                            </NavDropdown.Item>
                        ))}
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='navigation-item' onClick={onCreateProject}>New Project</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="File" id="basic-nav-dropdown" className='vs-bg white-text'>
                        <NavDropdown.Item className='navigation-item' onClick={onCreateMod}>New Mod <HotKeyHint keys={['Alt', 'N']} /></NavDropdown.Item>
                        {/* Seperator */}
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='navigation-item' onClick={onCreateModPack}>New Package <HotKeyHint keys={['Ctrl', 'P']} /></NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='navigation-item' onClick={onSaveModPacks}>Save All <HotKeyHint keys={['Ctrl', 'S']} /></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Deployment" id="basic-nav-dropdown" className='vs-bg black-text'>
                        {(databaseIsRunning && gameServerIsRunning) ? (
                            <NavDropdown.Item className='navigation-item' onClick={stopProject}>Stop Servers</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={startProject}>Start Servers</NavDropdown.Item>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='navigation-item' onClick={onDeploy}>Deploy Mods <HotKeyHint keys={['Ctrl', 'D']} /></NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="View" id="basic-nav-dropdown" className='vs-bg black-text'>
                        {guiEnabled ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowSource}>Show Source <HotKeyHint keys={['Alt', 'U']} /></NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowGui}>Show GUI <HotKeyHint keys={['Alt', 'U']} /></NavDropdown.Item>
                        )}
                        {showEditor ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowEditor}>Hide Editor</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowEditor}>Show Editor</NavDropdown.Item>
                        )}  
                        {showExplorer ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowExplorer}>Hide Explorer</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowExplorer}>Show Explorer</NavDropdown.Item>
                        )}
                        {showLogger ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowLogger}>Hide Logger</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowLogger}>Show Logger</NavDropdown.Item>
                        )}
                        {showUnity ? (
                            <NavDropdown.Item className='navigation-item' onClick={onShowUnity}>Hide Unity</NavDropdown.Item>
                        ) : (
                            <NavDropdown.Item className='navigation-item' onClick={onShowUnity}>Show Unity</NavDropdown.Item>
                        )}
                    </NavDropdown>
                    {/*
                    <Nav.Link href="#link" className='wheat-text'>Help</Nav.Link>
                    <Nav.Link href="#link" className='wheat-text'>About</Nav.Link>
                    */}
                </Nav>
            </Navbar.Collapse>

            {showCreateModPack &&
                <CreateModPackCard value={{}} save={(value) => {
                    onFinishCreateModPack(value);
                } } onClose={() => {
                    setShowCreateModPack(false);
                }} />
            }
            {showCreateMod &&
                <CreateModCard save={(value) => {
                    onFinishCreateMod(value);
                } } onClose={() => {
                    setShowCreateMod(false);
                }} />
            }
            {showCreateProject &&
                <CreateProjectCard onSave={(value) => {
                    onFinishCreateProject(value);
                } } onClose={() => {
                    setShowCreateProject(false);
                }} />
            }
        </Navbar>
    );
};
