import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import './components.css';

interface GenericEnumProps {
    title?: string;
    options: string[];
    value: any;
    titleClassNames?: string;
    setValue: (value: any) => void;
    formatOption?: (option: string) => string;
}

export const GenericEnum: React.FC<GenericEnumProps> = ({ title, options, value, titleClassNames, setValue, formatOption }) => {
    const titleClassNamesFinal = titleClassNames ? titleClassNames : 'center-text';
    return (
        <Row>
            {title && (
            <Col md={3} className={titleClassNamesFinal}>
                <p className='wheat-text'>{title}</p>
            </Col>
            )}
            <Col md={title ? 9 : 12}>
                <select className={"wheat-text vs-bg w-100 generic-input"}
                    value={value} onChange={(e) => { setValue(e.target.value); }}>
                    {options.map((option, index) => {
                        return <option className='vs-bg generic-input wheat-text' key={index} value={option}>{formatOption ? formatOption(option) : option}</option>;
                    })}
                </select>
            </Col>
        </Row>
    );
};