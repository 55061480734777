import { useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
import { ModExplorerEntry } from './ModExplorerEntry';
// States
import { useState } from 'react';

import { modService } from './ModService';
import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { CreateModPackCard } from './CreateModPackCard';
import { ResourceExplorer } from './ResourceExplorer';
import { OpenFile } from './OpenFile';

interface ExplorerProps {
    openEditors: OpenFile[];
    setOpenEditors: (openEditors: OpenFile[]) => void;
}

export const Explorer: React.FC<ExplorerProps> = ({ openEditors, setOpenEditors }) => {
    const [packs, setPacks] = useState<string[] | null>([]);
    const [selectedPack, _setSelectedPack] = useState<string>(Cookies.get('package') || "");
    const [entries, setEntries] = useState<string[] | null>([]);
    const [resources, setResources] = useState<string[] | null>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showCreateModPack, setShowCreateModPack] = useState<boolean>(false);
    const [modPackValue, setModPackValue] = useState<any>({});
    const [showResourceExplorer, setShowResourceExplorer] = useState<boolean>(false);

    const packRef = useRef<string>(selectedPack);

    const setSelectedPack = (pack: string) => {
        _setSelectedPack(pack);
        packRef.current = pack;
    };

    const updateEntries = () => {
        modService.getModPacks().then(data => {
            setPacks(data);

            if (!data.includes(packRef.current)) {
                setSelectedPack(data[0]);

                // Update cookie
                Cookies.set('package', data[0], { expires: 7, path: '', sameSite: 'strict' });
            }
        });

        setLoading(true);

        if (!packRef.current) {
            return;
        }

        // Use a POST request to get the list of files
        // 'localhost:8080/api/pack'
        modService.getMods(packRef.current).then(data => {
            // Update the state
            setEntries(data);

            setLoading(false);
        });

        /*modService.getPackResources(selectedPack).then(data => {
            setResources(data);
        });*/
    };

    useEffect(() => {
        updateEntries();
    }, [selectedPack]);

    // Fetch the list every 5 seconds
    useEffect(() => {
        // Fetch the cookie if it exists
        const cookie = Cookies.get('package');

        if (cookie) {
            setSelectedPack(cookie);

            collectModPackInformation(cookie);

            updateEntries();
        }
    
        const interval = setInterval(() => {
            updateEntries();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const collectModPackInformation = (pack: string) => {
        modService.getPackInformation(pack).then(data => {
            setModPackValue(data);
        });
    }

    const onSelectedPackChange = (pack: string) => {
        // Set a cookie with the selected pack
        Cookies.set('package', pack, { expires: 7, path: '', sameSite: 'strict' });

        setSelectedPack(pack);

        collectModPackInformation(pack);
    }

    const onFinishEditModPack = (value: any) => {
        modService.updatePack(modPackValue.name, value.name, value.description, value.author, value.version).then(data => {
            if (!data) {
                alert("Error updating mod pack");
                return;
            }

            setShowCreateModPack(false);
            setModPackValue(value);

            setSelectedPack(value.name);

            updateEntries();
        });
    }

    const onEditModPack = () => {
        modService.getPackInformation(selectedPack).then(data => {
            setModPackValue(data);

            setShowCreateModPack(true);
        });
    }

    return (
        <div id='explorer-container'>
            <div className='wh-100'>
                <div className='explorer-inputs'>
                    <Row className='mp-0 pb-2'>
                        <Col md={12}>
                            <select className='form-select vs-bg generic-input wheat-text' onChange={(e) => onSelectedPackChange(e.target.value)} value={selectedPack || ""}>
                                {packs && packs.map((pack, index) => {
                                    return <option className='vs-bg generic-input wheat-text' key={index} value={pack}>{pack}</option>;
                                })}
                            </select>
                        </Col>
                    </Row>
                    <Row className='mp-0 pb-2'>
                        <Col md={12}>
                            <GenericBtn onClick={() => onEditModPack()} title='Edit package' />
                        </Col>
                    </Row>
                    <Row className='mp-0 border-bottom pb-2'>
                        <Col md={12}>
                            <GenericBtn onClick={() => setShowResourceExplorer(true)} title='Browse resources' />
                        </Col>
                    </Row>
                </div>
                <div className='explorer-mods'>
                    <Row className='scrollable-h-100'>
                        {entries && entries.map((entry, index) => {
                            return <ModExplorerEntry key={index} name={entry} isResource={false} openEditors={openEditors} setOpenEditors={setOpenEditors} />;
                        })}
                        {entries?.length == 0 && <p className='wheat-text'>No files found</p>}
                    </Row>
                </div>
            </div>
            {showCreateModPack &&
                <CreateModPackCard value={modPackValue} save={(value) => {
                    onFinishEditModPack(value);
                } } onClose={() => {
                    setShowCreateModPack(false);
                }} />
            }
            {showResourceExplorer &&
                <ResourceExplorer pack={selectedPack} path='' onSelect={(value) => {}} onHidden={() => {
                    setShowResourceExplorer(false);
                }} />
            }
       </div>
    );
}