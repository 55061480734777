import { useEffect, useRef } from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import './components.css';
// States
import { useState } from 'react';

import { modService } from './ModService';
import Cookies from 'js-cookie';
import { GenericBtn } from './GenericBtn';
import { GenericInput } from './GenericInput';
import { GenericEnum } from './GenericEnum';
import { GenericText } from './GenericText';
import { FormatEnumOption } from './Formatters';

interface NewModProps {
    card: boolean;
    save: (value: any) => void;
}

export const NewMod: React.FC<NewModProps> = ({ card, save }) => {
    const [packs, setPacks] = useState<string[] | null>([]);
    const [selectedPack, _setSelectedPack] = useState<string>(Cookies.get('package') || "");
    const [modTypes, setModTypes] = useState<string[]>([]);

    const [modName, setModName] = useState<string>("");
    const [modType, setModType] = useState<string>("object");

    const packRef = useRef<string>(selectedPack);

    const setSelectedPack = (pack: string) => {
        _setSelectedPack(pack);
        packRef.current = pack;
    };

    const updateEntries = () => {
        modService.getModPacks().then(data => {
            setPacks(data);
        });

        if (!packRef.current) {
            return;
        }
    };

    useEffect(() => {
        updateEntries();
    }, [selectedPack]);

    // Fetch the list every 5 seconds
    useEffect(() => {
        // Fetch the cookie if it exists
        const cookie = Cookies.get('package');

        setModName(cookie + ":");

        if (cookie) {
            setSelectedPack(cookie);

            updateEntries();
        }
    
        const interval = setInterval(() => {
            updateEntries();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        modService.getModTemplateNames().then(data => {
            setModTypes(data);
        });
    }, []);

    const onSelectedPackChange = (pack: string) => {
        setSelectedPack(pack);

        if (!modName.startsWith("lego-universe:"))
        {
            // Replace what comes before : with the new pack
            setModName(pack + ":" + modName.substring(modName.indexOf(':') + 1));
        }
    }

    const finishMod = () => {
        let template: any = {
            "name": modName,
            "type": modType
        }

        /*modService.createMod(template).then(data => {
            window.location.href = `/?uid=${data.uid}`;
        });*/
    }

    return (
        <div>
            <Row className='mp-0 border-bottom'>
                <Col md={card ? 12 : 3}>
                    <GenericEnum title='Package' options={packs || []} value={selectedPack} setValue={onSelectedPackChange} />
                </Col>
                <Col md={card ? 12 : 3}>
                    <GenericInput title='Name' type="text" value={modName} setValue={setModName} />
                </Col>
                <Col md={card ? 12 : 3}>
                    {modTypes.length > 0 &&
                    <GenericEnum title='Type' options={modTypes} value={modType} setValue={setModType} 
                        formatOption={(option) => FormatEnumOption(option)}
                    />
                    }
                    {modTypes.length === 0 &&
                    <GenericText title='Fetching...' />
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} className='center-text'>
                    <GenericBtn title='Save' onClick={finishMod} />
                </Col>
            </Row>
        </div>
    );
}