import React, { useEffect, useState } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { GenericInput } from './GenericInput';
import { GenericBtn } from './GenericBtn';

interface CreateModPackCardProps {
    value: any;
    save: (value: {name: string, description: string, author: string, version: string}) => void;
    onClose: () => void;
}

export const CreateModPackCard: React.FC<CreateModPackCardProps> = ({ value, save, onClose }) => {
    const [show, setShow] = useState<boolean>(true);
    const [name, setName] = useState<string>(value?.name || "");
    const [description, setDescription] = useState<string>(value?.description || "");
    const [author, setAuthor] = useState<string>(value?.author || "");
    const [version, setVersion] = useState<string>(value?.version || "");

    return (
        <Modal show={show} onHide={() => {
            setShow(false);
            onClose();
        }}>
            <Card className='vs-bg p-2'>
                <Row className='center-text'>
                    <p className='wheat-text'>New package</p>
                </Row>
                <GenericInput title='Name' type='text' value={name} setValue={setName} />
                <GenericInput title='Description' type='textarea' value={description} setValue={setDescription} />
                <GenericInput title='Author' type='text' value={author} setValue={setAuthor} />
                <GenericInput title='Version' type='text' value={version} setValue={setVersion} />
                <Row className='center-text'>
                    <Col>
                        <GenericBtn title='Save' onClick={() => {
                            save({name, description, author, version});
                        }} />
                    </Col>
                </Row>
            </Card>
        </Modal>
    );
};