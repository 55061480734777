import { useEffect } from 'react';

import './components.css';
import { ModExplorerEntry } from './ModExplorerEntry';
// States
import { useState } from 'react';

import { modService } from './ModService';
import Cookies from 'js-cookie';

export const Auth = () => {
    const [success, setSuccess] = useState<boolean | null>(null);
    let sent = false;

    // Get the 'code' from the URL

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
    
        const code = urlParams.get('code');

        if (code && !sent) {
            sent = true;
            modService.auth(code).then(data => {
                if (data === "") {
                    setSuccess(false);

                    window.location.href = '/';

                    return;
                }

                // Add a cookie, set the SameSite attribute to 'None', share with all subdomains, and set the Secure attribute
                Cookies.set('token', data, { expires: 3600, path: '/', domain: 'nejlika.org', sameSite: 'none', secure: true });

                setSuccess(true);

                window.location.href = '/';
            });
        }
        else {
            setSuccess(false);
        }
    }, []);

    return (
        <div id='explorer-container'>
            <h1 className='wheat-text'>Authenticating...</h1>
        </div>
    );
}